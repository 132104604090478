import React, { useState } from "react";
import style from "./ContactUs.module.css";
import { Helmet } from "react-helmet";
import { indigo } from "@mui/material/colors";
import success from "../../Assets/animation/success.json";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { Slide } from "react-awesome-reveal";
import TextField from "@mui/material/TextField";
import { Box, Button, Stack } from "@mui/material";

const phoneRegex =
  /^\+?(\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, "min char is 3")
    .max(50, "max char is 50")
    .required("name is required"),
  message: yup.string().min(5, "min char is 5").required("message is required"),
  phone: yup
    .string()
    .matches(phoneRegex, "invalid phone number")
    .required("phone is required"),
  email: yup.string().email("Email is invalid").required("Email is required"),
});

export default function ContactUs() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();


  const [res , setRes] = useState()
  const [submitting , setSubmitting] = useState();

  const onSubmit = async (data) => {
    try {
      const response = await fetch("https://formspree.io/f/mpwaroje", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      setRes(response.ok)
      setSubmitting(response.status)
    
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  console.log(submitting)
  
  
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const { t } = useTranslation();

  return <section className={`${style.container}`}>
      <Helmet>
        <title>{t("contact")}</title>
      </Helmet>

      <h1 className={`text-center mt-5 fw-bold mb-5 ${style.header}`}>
        {t("contactPage.title")}
      </h1>

      <Slide>
        <div className="row py-5">
          <div className={` ${style.connectStyle} pt-3 col-md-4   `}>
            <div className=" d-flex justify-content-center align-items-center   mb-4">
              <i className="fa-solid fa-location-arrow fs-4 text-center"></i>
            </div>
            <h6 className="fw-bold fs-3 text-center">
              {t("contactPage.address")}
            </h6>
            <p className="text-secondary fw-medium text-center">
            {t("contactPage.location")}
            </p>
          </div>
          <div className={` ${style.connectStyle} pt-3 col-md-4   `}>
            <div className=" d-flex justify-content-center align-items-center mb-4">
              <i className="fa-regular fa-envelope fs-4 text-center"></i>
            </div>
            <h6 className="fw-bold fs-3 text-center">
              {t("contactPage.email")}
            </h6>
            <p className="text-secondary fw-medium text-center">
              Support@website.com
            </p>
          </div>
          <div className={` ${style.connectStyle} pt-3 col-md-4   `}>
            <div className=" d-flex justify-content-center align-items-center  mb-4">
              <i className="fa-solid fa-phone fs-4 text-center"></i>
            </div>
            <h6 className="fw-bold fs-3 text-center">
              {t("contactPage.phoneNumber")}
            </h6>
            <p className="text-secondary fw-medium text-center">
               {t("contactPage.number")}
            </p>
          </div>
        </div>
      </Slide>

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <Stack>
          <label htmlFor="name" className="fs-5 fw-semibold">
            {t("contactPage.name")}
          </label>
          <TextField
            sx={{ flex: 1 }}
            id="name"
            className="standard-basic"
            variant="standard"
            {...register("name", { required: true })}
            error={errors.name ? true : false}
            helperText={errors.name ? t("contactPage.nameError") : null}
          />
        </Stack>

        <Stack direction={"row"} className="my-4" sx={{ gap: 3 }}>
          <Stack sx={{ width: "50%" }}>
            <label htmlFor="email" className="fs-5 fw-semibold">
              {t("contactPage.email")}
            </label>
            <TextField
              sx={{ flex: 1 }}
              id="email"
              className="standard-basic"
              variant="standard"
              {...register("email", { required: true, pattern: emailRegex })}
              error={errors.email ? true : false}
              helperText={errors.email ? t("contactPage.emailErorr") : null}
            />
          </Stack>

          <Stack sx={{ width: "50%" }}>
            <label htmlFor="phone" className="fs-5 fw-semibold">
              {t("contactPage.phoneNumber")}
            </label>
            <TextField
              sx={{ flex: 1 }}
              id="phone"
              className="standard-basic"
              variant="standard"
              {...register("phone", { required: true, pattern: phoneRegex })}
              error={errors.phone ? true : false}
              helperText={errors.phone ? t("contactPage.phoneErorr") : null}
            />
          </Stack>
        </Stack>
        <Stack>
          <label htmlFor="message" className="fs-5 fw-semibold">
            {t("contactPage.message")}
          </label>
          <TextField
            sx={{ flex: 1 }}
            className="standard-multiline-static"
            id="message"
            multiline
            variant="standard"
            rows={1}
            {...register("message", { required: true, minLength: 3 })}
            error={errors.message ? true : false}
            helperText={errors.message ? t("contactPage.messageErorr") : null}
          />
        </Stack>
        <Box className="w-100 d-flex justify-content-center">
          <Button
            type="submit"
            disabled={submitting}
            variant="contained"
            sx={{ width: { xs: "50%", sm: "25%" } }}
            className={` fs-6 text-capitalize py-2 fw-semibol text-white  mt-5 ${style.buttonStyle}`}
          >
            {t("contactPage.submit")} <SendRoundedIcon className="mx-2" />
          </Button>
        
        </Box>
        {
          res && <p className='h5 fw-semibold  mt-3 d-flex align-items-center justify-content-center'>
             <Lottie loop={false} animationData={success} className={`${style.successAnimate}`} />
             {t("contactPage.submitMessage")}
           </p>
        }
      </Box>

    </section>
  
}
