import React from 'react'
import style from "./Home.module.css"
import { Roll } from "react-awesome-reveal";

import { useTranslation } from "react-i18next";


import coverCmg from "../../Assets/Pic/cmg-copy.jpg";
import agri from "../../Assets/Pic/agricl.jpg";
import industry from "../../Assets/Pic/indusry.jpg";
import development from "../../Assets/Pic/real-estate.jpg";
import rest from "../../Assets/Pic/rest-image-cover.jpg";

import Slider from "react-slick";



export default function SlideBar() {

  const { t } = useTranslation();


    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        pauseOnHover: true,
        arrows: false,
      };

      
      
  let arrCover = [
    { id: 1, imgUrl: coverCmg, text: t("homePage.cmg"), alt: "cover company" },
    {
      id: 2,
      imgUrl: development,
      text: t("homePage.development"),
      alt: "cover real estate",
    },
    { id: 3, imgUrl: rest, text: t("homePage.rest"), alt: "resturant" },
    { id: 4, imgUrl: industry, text: t("homePage.industry"), alt: "industry" },
    { id: 5, imgUrl: agri, text: t("homePage.agri"), alt: "agriculture" },
  ];


  return <>
   <div className="slider-container">
          <Slider {...settings}>
            {arrCover.map((item) => {
              return (
                <div>
                  <div className=" position-relative" key={item.id}>
                    <div
                      className={`${style.coverStyle} position-absolute top-0 bottom-0 start-0 end-0 d-flex justify-content-center align-items-center`}
                    >
                      <Roll>
                        <h1
                          className={` text-uppercase text-white ${style.titleStyle} fw-bold`}
                        >
                          {item.text}
                        </h1>
                      </Roll>
                    </div>
                    <img
                      src={item.imgUrl}
                      className={`${style.coverPic} img-fluid`}
                      alt={item.alt}
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
  
  </>
}
