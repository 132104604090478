import React from "react";
import style from "./NotFound.module.css";
import Lottie from "lottie-react";
import erorr404 from "../../Assets/animation/notFound.json"

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  
  function backHome(){
    navigate("./home")
  }
  return (
    <>
    <div className={`${style.notFoundContainer} vh-100 `}>
    <div className="d-flex justify-content-center ">
    <Lottie animationData={erorr404} className={`${style.error404} `}  />
    </div>
    <div className="w-100 d-flex justify-content-center">
    <button onClick={()=>{backHome()}} className={`${style.btnNot}  btn text-white py-2 fs-3  rounded-5`}>{t("notFound")}</button>
    </div>
    </div>   
    

    
    </>
  );
}
