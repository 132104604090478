import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Componant/Home/Home";
import Layout from "./Componant/Layout/Layout";
import About from "./Componant/About/About";
import ContactUs from "./Componant/ContactUs/ContactUs";
import NotFound from "./Componant/NotFound/NotFound";
import ProjectDetails from "./Componant/ProjectDetails/ProjectDetails";
import { RecoilRoot } from "recoil";

export default function App() {
  let routers = createBrowserRouter([
    {
      path: "",
      element: <Layout />,
      children: [
        { path: "", element: <Home /> },
        { path: "About", element: <About /> },
        { path: "ContactUs", element: <ContactUs /> },
        { path: "projectDetails/:id", element: <ProjectDetails /> },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  return (
    <>
    <RecoilRoot>
    
    <RouterProvider router={routers} />
    </RecoilRoot>

    </>
  );
}
