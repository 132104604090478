import React from 'react'
import { Zoom } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import style from "./Home.module.css"

import vrCmg from "../../Assets/Pic/newAbout.jpeg"



export default function Destination() {

    const { t } = useTranslation();

  return <>
  
  <div>
        <h1 className={`text-center fw-bold  ${style.header}`}>
        {t("homePage.title")}
      </h1>
      <div className="row my-3">
        <div className={`col-lg-3 ${style.picBorder} mt-5`}>
            <img
              src={vrCmg}
              className={`img-fluid ${style.picAbout}`}
              alt="image Logo"
            />
        </div>

        <div className={` col-lg-9  d-flex flex-column justify-content-center ${style.spaceBetween}`}>
          <Zoom>
            <p className={`h1 mb-5 ${style.profileStyle} ${style.sizeProfile}`}>
              <span className=" fw-bolder text-decoration-underline">
              {t("homePage.vision")}: 
              </span>
              <br />
              {t("homePage.descVision")}

            </p>
          </Zoom>

          <Zoom>
            <p className={`h1 ${style.sizeProfile}`}>
              <span className=" fw-bolder text-decoration-underline ">
              {t("homePage.mission")}: 
              </span>
              <br />
              {t("homePage.descMission")}
            </p>
          </Zoom>
        </div>
      </div>
        </div>

  
  </>
}
