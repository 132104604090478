import {atom} from "recoil"

export const myProjects = atom({
    key: 'myProjects', 
    default: [
      { id: 1 , name :"Mina" , age : "25"},
      { id: 2 , name :"Omar" , age : "35"},
      { id: 3 , name :"Noura" , age : "23"},
    ], 
  });



  
// import { useRecoilState } from "recoil";
// import { myProjects } from "../../Atoms/ProjectAtom";
  // const [project, setProject] = useRecoilState(myProjects);
  // const [arr, setArr] = useState(project);

// import { Link, useNavigate } from "react-router-dom";


      {/* {arr.map((item) => (
        <>
          <div key={item.id}>
            <Link
              to={`/projectDetails/${item.id}`}
              className="text-decoration-none text-black fw-bolder"
            >
              {item.name}
            </Link>
          </div>
        </>
      ))} */}

// {loading ? (
//   <ClipLoader color="#3498db" loading={loading} size={50} />
// ) : (
//   <>
//     <div className=" d-flex">
//       <Lottie animationData={anim1} className="w-25" loop={true} />
//     </div>

//     <h1>{t("welcome")}</h1>

//     <button className="btn btn-primary" onClick={setNavigate}> submit</button>
//   </>
// )}


