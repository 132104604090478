import React, { useState, Component } from "react";
import { useTranslation } from "react-i18next";

import style from "./Home.module.css";

import { Helmet } from "react-helmet";

import SlideBar from "./SlideBar";
import Destination from "./Destination";
import Department from "./Department";
import Gps from "./Gps";


export default function Home() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("home")}</title>
      </Helmet>

      <section className={`${style.home} `}>

      <SlideBar/>

        <hr className={` ${style.hrStyle} mt-2`} />

        <div className={`my-5 ${style.container}`}>
    
      <Destination/>

        <hr className={` ${style.hrStyle} mt-3`} />

      <Department/>

      <hr className={` ${style.hrStyle} mt-3`} />


      <Gps/>


        </div>

      </section>


    </>
  );
}

