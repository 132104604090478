import React from 'react'
import { myProjects } from '../../Atoms/ProjectAtom';
import { useRecoilState } from 'recoil';
import { useParams } from "react-router-dom"

import style from  "./ProjectDetails.module.css"



export default function ProjectDetails() {

    const [project , setProject] = useRecoilState(myProjects);
    let {id} = useParams()
  
    const addArr = [];
  
   project.map((item) => {
    if(item.id == id){
      addArr.push(item)
    }
   })
  


  return  (
    <>
   
    {
      addArr.map((item)=>  <div className="container" key={item.id}>
        <div className="card"  >
        <img src="..." className="card-img-top" alt="..."/>
        <div className="card-body">
          <h5 className="card-title">{item.name}</h5>
          <p className="card-text">{item.age}</p>
        </div>
      </div>
      </div>
            )}
   
    </>
  );
}
